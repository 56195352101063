export default {
  headerStyles: {
    fontFamily: "Roboto",
    color: "#3f51b5",
    padding: "0.5rem",
  },

  containerStyles: {
    margin: "0.5rem 0 0 0",
  },
};
