export default {
  baseStyle: {
    width: "20%",
    margin: "1rem",
    background: "white",
    minHeight: "8rem",
    minWidth: "15rem",
    cursor: "pointer",
  },
};
